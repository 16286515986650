import type { NextApiRequest, NextApiResponse, NextPageContext } from "next"
import nookies from 'nookies'
import resolveAcceptLanguage from 'resolve-accept-language'

/**
 * Get the actual locale based on the current locale from Next.js.
 *
 * To get a dynamic locale resolution on `/` without redirection, we need to add a "multilingual" locale as the
 * default locale so that we can identify when the homepage is requested without a locale. With this setup it
 * also means that we can no longer easily know what is the current locale. This function is meant to return the
 * actual current of locale by replacing the "multilingual" default locale by the actual default locale.
 *
 * @param locale - The current locale from Next.js.
 * @param defaultLocale - The configured i18n default locale from Next.js.
 * @param locales - The configured i18n locales from Next.js.
 *
 * @returns The list of actual locales.
 */
export function getActualLocale(locale: string | undefined, defaultLocale: string | undefined, locales: string[] | undefined): string | undefined {
    const actualDefaultLocale = getActualDefaultLocale(locales, defaultLocale);
    return locale === defaultLocale ? actualDefaultLocale : locale;
}

export function getActualLocales(locales: string[] | undefined, defaultLocale: string | undefined): string[] | undefined {
    return locales?.filter((locale) => locale !== defaultLocale);
}

export function getActualDefaultLocale(locales: string[] | undefined, defaultLocale: string | undefined): string | undefined {
    return getActualLocales(locales, defaultLocale)?.shift();
}


export function isLocale(locale: string, checkNormalizedCase: boolean = false): boolean {
    const regexp = new RegExp(/^[a-z]{2}-[A-Z]{2}$/, !checkNormalizedCase ? 'i' : '');
    return regexp.test(locale);
}

/**
 * Get a normalized locale identifier.
 *
 * We only uses locale identifiers following the `language`-`country` format. Locale identifiers
 * are case insensitive and can be lowercase, however it is recommended by ISO 3166 convention that language codes
 * are lowercase and country codes are uppercase.
 *
 * @param locale - A locale identifier.
 *
 * @returns The normalized locale identifier following the ISO 3166 convention.
 */
export function normalizeLocale(locale: string): string {
    if (!isLocale(locale)) {
        return locale;
    }
    const [languageCode, countryCode] = locale.split('-');
    return `${languageCode.toLowerCase()}-${countryCode.toUpperCase()}`;
}


// The name of the cookie used to store the user locale, can be overwritten in an `.env` file.
const LOCALE_COOKIE_NAME = process.env.NEXT_PUBLIC_LOCALE_COOKIE_NAME
    ? process.env.NEXT_PUBLIC_LOCALE_COOKIE_NAME
    : 'L';

// The lifetime of the cookie used to store the user locale, can be overwritten in an `.env` file.
const LOCALE_COOKIE_LIFETIME = process.env.NEXT_PUBLIC_LOCALE_COOKIE_LIFETIME
    ? process.env.NEXT_PUBLIC_LOCALE_COOKIE_LIFETIME
    : 60 * 60 * 24 * 365 * 10;


/**
 * Save the current user's locale to the locale cookie.
 *
 * @param locale - A locale identifier.
 */
export function setCookieLocale(locale: string | undefined, ctx: Pick<NextPageContext, "res"> | { res: NextApiResponse<any> } | null | undefined = null): void {
    if (locale) {
        nookies.set(ctx, LOCALE_COOKIE_NAME, locale, {
            maxAge: LOCALE_COOKIE_LIFETIME,
            path: '/',
        })
    }
}


/**
 * Get the locale that was saved to the locale cookie.
 *
 * @param nextPageContext - The Next.js page context.
 * @param actualLocales - The list of actual locales used by `next-multilingual`.
 *
 * @returns The locale that was saved to the locale cookie.
 */
export function getCookieLocale(nextPageContext: NextPageContext | { req: NextApiRequest, res: NextApiResponse<any> } | null | undefined, actualLocales: string[] | undefined): string | undefined {
    const cookies = nookies.get(nextPageContext);

    if (!Object.keys(cookies).includes(LOCALE_COOKIE_NAME)) {
        return undefined;
    }
    const cookieLocale = cookies[LOCALE_COOKIE_NAME];

    if (!actualLocales?.includes(cookieLocale)) {
        // Delete the cookie if the value is invalid (e.g. been tampered with).
        nookies.destroy(nextPageContext, LOCALE_COOKIE_NAME);
        return undefined;
    }

    return cookieLocale;
}

/**
 * Resolve the preferred locale from an HTTP `Accept-Language` header.
 *
 * @param acceptLanguageHeader - The value of an HTTP request `Accept-Language` header.
 * @param actualLocales - The list of actual locales used by `next-multilingual`.
 * @param actualDefaultLocale - The actual default locale used by `next-multilingual`.
 *
 * @returns The preferred locale identifier.
 */
export function getPreferredLocale(
    acceptLanguageHeader: string,
    actualLocales: string[],
    actualDefaultLocale: string
): string {
    return resolveAcceptLanguage(acceptLanguageHeader, actualLocales, actualDefaultLocale);
}


export function resolveLocale(nextPageContext: NextPageContext): string
{
    const { req, locale, locales, defaultLocale } = nextPageContext

    const actualLocales = getActualLocales(locales, defaultLocale) || []
    const actualDefaultLocale = getActualDefaultLocale(locales, defaultLocale) || ""
    const cookieLocale = getCookieLocale(nextPageContext, actualLocales)
    let resolvedLocale = getActualLocale(locale, defaultLocale, locales) as string

    // When Next.js tries to use the default locale, try to find a better one.
    if (locale === defaultLocale) {
        resolvedLocale = cookieLocale
            ? cookieLocale
            : getPreferredLocale(
                req?.headers['accept-language'] || "",
                actualLocales,
                actualDefaultLocale
            )
    }
    return resolvedLocale
}
