// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://8945e3882ff6404b9ebf6dfc63c51702@snt.iuvia.io/2',

  integrations: [
    new Sentry.Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({ levels: ['warn', 'error', 'assert']}),
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  tunnel: '/api/snt',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
