import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { appWithTranslation, useTranslation } from 'next-i18next'

import '../styles/accordion.css'
import '../styles/icomoon.css'
import '../styles/simple-line-icons.css'
import '../styles/globals.css'
import '../styles/survey.css'
import '../styles/worksans.css'
import '../styles/landing.css'
import { useRouter } from 'next/router'
import { getActualDefaultLocale, getActualLocale, getActualLocales, getCookieLocale, getPreferredLocale, setCookieLocale } from '../components/i18n'
import { NextPageContext } from 'next'

function MyApp({ Component, pageProps }: AppProps) {
  /**
   * Next.js always expose the default locale with URLs without prefixes. If anyone use these URLs, we want to overwrite them
   * with the actual (default) locale.
   */
  // setCookieLocale(pageProps.resolvedLocale) // Persist locale on page load (will be re-used when hitting `/`).

  return <Component {...pageProps} />
}

export const ServerSideProps = async (ctx: NextPageContext) => {
  const { locale, defaultLocale, locales, req } = ctx
  
  const actualLocales = getActualLocales(locales, defaultLocale) || []
  const actualDefaultLocale = getActualDefaultLocale(locales, defaultLocale) || ""
  const cookieLocale = getCookieLocale(null, actualLocales)
  let resolvedLocale = getActualLocale(locale, defaultLocale, locales) as string

  if (locale === defaultLocale) {
    resolvedLocale = cookieLocale
        ? cookieLocale
        : getPreferredLocale(
            req?.headers['accept-language'] || "",
            actualLocales,
            actualDefaultLocale
        )
  }

  ctx.locale = resolvedLocale
  const { i18n } = useTranslation()
  await i18n.changeLanguage(ctx.locale)

  return {
    props: {
      resolvedLocale
    }
  }
}

export default appWithMultilang(appWithTranslation(MyApp))

function appWithMultilang (WrappedComponent: any) {
  return (props: any) => {
    const router = props.router
    const resolvedLocale = props.pageProps.resolvedLocale
    if (resolvedLocale) {
      router.locale = resolvedLocale
    }

    return WrappedComponent(props)
  }
}
